<template>
  <div class="dispute-apply" v-if="orderInfo">
    <div class="basic-info">
      <img src="@/assets/images/dispute/logo.png" class="logo" alt="" />
      <div class="info">
        <div class="line">{{ $t('message.dispute.nameHello', { name: orderInfo.firstName }) }}</div>
        <div class="line" v-html="$t('message.dispute.orderInfo', { date: orderInfo.createAt, link: getDomain(orderInfo.applicationDomain), website: orderInfo.applicationDomain, currency: orderInfo.tradeCurrency, amount: orderInfo.tradeAmount})"></div>
        <div class="line">{{ $t('message.dispute.tip') }}</div>
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="form-wrapper">
        <div class="title">{{ $t('message.dispute.apply') }}</div>
        <a-form-model class="form" :colon="false" label-align="left" ref="formRef" :model="form" :rules="formRules">
          <!-- 卡号后4位 -->
          <a-form-model-item
            :label="$t('message.dispute.cardNoLast4')"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            prop="cardNo"
          >
            <a-input :size="size" v-model="form.cardNo" :placeholder="$t('message.common.inputPlaceholder', {field: $t('message.dispute.cardNoLast4')})" />
          </a-form-model-item>
          <!-- 争议类型 -->
          <a-form-model-item
            :label="$t('message.dispute.type')"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            prop="type"
          >
            <a-select :size="size" v-model="form.type" :placeholder="$t('message.common.selectPlaceholder', {field: $t('message.dispute.type')})"
              :allowClear="true">
              <a-select-option v-for="(item, index) in disputeTypeOptions" :value="item.value" :key="index">
                {{ $t(item.label) }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 争议诉求 -->
          <a-form-model-item
            :label="$t('message.dispute.claims')"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol"
            prop="content"
          >
            <a-textarea :maxLength="500" :rows="6" :size="size" v-model="form.content" :placeholder="$t('message.dispute.claimsPlaceholder')" />
          </a-form-model-item>
          <a-form-model-item :wrapperCol="{14: 3, offset: 6}">
            <a-button @click="submit" type="primary" :size="size">{{ $t('message.dispute.comfirm') }}</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { getDisputeOrder, applyDispute } from '@/views/dispute/api/index';
import { DISPUTE_TYPE } from '@/enums/dispute';

export default {
  data() {
    return {
      orderInfo: null,
      size: 'large',
      // UNKNOWN(0, "未知"),
      // FRAUD(1, "欺诈"),
      // UNAUTHORIZED(2, "未授权"),
      // ITEM_NOT_RECEIVED(3, "货不对版");
      disputeTypeOptions: DISPUTE_TYPE,
      form: {
        cardNo: '',
        orderId: this.$route.query.id,
        content: '',
        type: undefined
      },
      formRules: {
        cardNo: [
          { required: true, message: this.$t('message.common.inputPlaceholder', { field: this.$t('message.dispute.cardNoLast4') }) }
        ]
      },
      formItemLayout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 14
        },
        labelAlign: 'left'
      },
      loading: true
    };
  },
  created() {
    getDisputeOrder(this.form.orderId).then((res) => {
      this.orderInfo = res.data;
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    })
  },
  methods: {
    submit() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.loading = true;
          applyDispute(this.form).then((res) => {
            this.loading = false;
            this.$router.push({
              path: '/dispute/chat',
              query: {
                token: res.data,
                id: this.form.orderId
              }
            })
          }).catch(() => {
            this.loading = false;
          })
        }
      });
    },
    getDomain() {
      const domain = this.orderInfo.applicationDomain;
      if (domain.indexOf('http') === -1) {
        return `https://${domain}`;
      } else {
        return domain;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';
.dispute-apply {
  width: 900px;
  max-width: 1000%;
  height: 100%;
  margin: 0 auto;
  .basic-info {
    background-color: #FFF;
    margin-top: 20px;
    padding: 32px;
    .logo {
      height: 26px;
    }
    .info {
      font-size: 16px;
      line-height: 19px;
      color: @title-color;
      .line {
        margin-top: 20px;
        &:first-child {
          margin-top: 32px;
        }
      }
      ::v-deep(.date) {
        color: @warn-color;
      }
      ::v-deep(.amount) {
        color: @warn-color;
      }
      ::v-deep(.website) {
        color: @bg-link-color;
      }
    }
  }
  .form-wrapper {
    margin-top: 16px;
    // padding: 0 32px 32px;
    background-color: #FFF;
    border-radius: 8px;
    .title {
      padding: 15px 32px;
      font-size: 16px;
      line-height: 22px;
      color: @title-color;
      border-bottom: 1px solid @border-color;
    }
    .form {
      padding: 24px 32px 32px;
      ::v-deep(.ant-form-item-required::before) {
        display: none;
      }
    }
  }
}
</style>
